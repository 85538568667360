import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const Auth0ProviderWithNavigate = ({ children }) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    const { returnTo = false } = appState;
    navigate(returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain="spanext.us.auth0.com"
      clientId="CYcM3SulqbHpipm8G51b0zUq9T4xmYzl"
      authorizationParams={{
        redirect_uri: `${window.location.origin}`,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithNavigate;

Auth0ProviderWithNavigate.propTypes = {
  children: PropTypes.element,
};
