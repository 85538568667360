import React, { useState, useRef } from 'react';
import { createWorker } from 'tesseract.js';
import './ScanLines.css';
import { CLIENTLIST, DecodeScanLines } from './util/DecodeScanLines';

/**
 * Component for processing scanlines
 */
const ScanLines = () => {
  const fileInput = useRef();
  const [imgFile, setImgFile] = useState();
  const [currentClient, setCurrentClient] = useState('');
  const [ocr, setOcr] = useState();
  const [isValid, setIsValid] = useState(null);
  const [cursor, setCursor] = useState('pointer');

  /**
   * Show the preview of the uploaded image
   */
  const showPreview = () => {
    setImgFile(URL.createObjectURL(fileInput.current.files[0]));
  };

  /**
   * Process the uploaded image with the tasseract worker
   */
  const doOCR = async () => {
    setCursor('wait');
    /**
     * Create a tesseract worker to process the image
     */
    const worker = await createWorker(
      'spl',
      1,
      {
        langPath: '/',
        legacyCore: true,
        gzip: false,
      },
    );
    const ret = await worker.recognize(imgFile);
    await worker.terminate();
    setOcr(ret.data.text.replace(/\s/g, ''));
    setCursor('pointer');
  };

  /**
   * Decode the scanline and check if the check digit is valid
   */
  const decodeScanline = () => {
    const ds = new DecodeScanLines({ currentClient, code: ocr });
    const check = ds.computeCheckDigit();
    setIsValid(check);
  };
  /**
   * Clear all of our inputs to process another scanline
   */
  const reset = () => {
    setCurrentClient('');
    setImgFile(null);
    setOcr(null);
    setIsValid(null);
  };

  return (
    <div className="container">
      <h1>Decode Scanline</h1>
      <p>Upload an image with scanline to decode</p>
      <div className="clientSelect">
        <label htmlFor="client">
          Select a client:&nbsp;
          <select
            name="client"
            id="client"
            value={currentClient}
            onChange={(e) => setCurrentClient(e.target.value)}
          >
            <option value={null}>---</option>
            {CLIENTLIST.map((client) => (
              <option key={client} value={Object.keys(client)}>
                {Object.keys(client)}
              </option>
            ))}
          </select>
        </label>
      </div>
      {currentClient && (
        <div className="upload">
          {!imgFile && <input type="file" onChange={showPreview} ref={fileInput} />}
          {imgFile && (
            <div className="imgPreview">
              <img src={imgFile} alt="uploaded file preview" />
              <button type="submit" onClick={doOCR} style={{ cursor }}>Preview</button>
            </div>
          )}
        </div>
      )}
      {ocr && (
        <div className="preview">
          <textarea
            value={ocr}
            onChange={(e) => setOcr(e.target.value)}
          />
        </div>
      )}
      {currentClient && ocr && (
      <div className="decode">
        <button type="submit" onClick={decodeScanline}>Decode</button>
      </div>
      )}
      {isValid !== null && (
      <div className="results">
        <div className="valid">
          Scanline Check Digit for
          {' '}
          {currentClient}
          {' '}
          is:
          <strong>
            {isValid ? 'Valid' : 'Not Valid'}
          </strong>
        </div>
        <div className="another"><button type="submit" onClick={reset}>Check another</button></div>
      </div>
      )}
    </div>
  );
};

export default ScanLines;
