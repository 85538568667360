import React from 'react';
import { Route, Routes } from 'react-router-dom';

import './App.css';
import { useAuth0 } from '@auth0/auth0-react';
import HomePage from './components/HomePage/HomePage';
import ScanLines from './components/ScanLines/ScanLines';

import WelcomePage from './components/WelcomePage/WelcomePage';
import Loading from './components/Spinner/Loading';

// auth/protect
import Logout from './components/Logout';
import Unauthorized from './components/401';
import ProtectedRoute from './components/ProtectedRoute';

const App = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Routes>
      <Route
        path="/"
        element={<WelcomePage />}
      />
      <Route path="/barcode" element={<ProtectedRoute component={HomePage} />} />
      <Route path="/scanline" element={<ProtectedRoute component={ScanLines} />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/401" element={<Unauthorized />} />

    </Routes>
  );
};

export default App;
