/* eslint-disable camelcase */
/* eslint-disable no-alert */
/* eslint-disable no-await-in-loop */
import React, { useRef, useEffect, useState } from 'react';
import { EnumBarcodeFormat_2, BarcodeReader } from 'dynamsoft-javascript-barcode';
import Loading from '../Spinner/Loading';
import './ImgDecode.css';

const ImgDecode = () => {
  const pReader = useRef(null);
  const fileInput = useRef();
  const [imgFile, setImgFile] = useState([]);
  const [imageUploads, setImageUploads] = useState([]);
  const [loading, setLoading] = useState(false);

  const showPreview = () => {
    if (fileInput.current.files[0].type === 'application/pdf') {
      setLoading(true);
      // TODO, figure out how we want to handle this when we deploy to production
      fetch('https://hodwkheab7.execute-api.us-east-1.amazonaws.com/sqanext-production/pdf', {
        method: 'POST',
        body: fileInput.current.files[0],
      })
        .then((response) => {
          if (response.status !== 200) {
            throw new Error('Lambda failed to convert PDF to images');
          }
          return response.json();
        })
        .then(async (imgs) => {
          setImgFile(imgs);
          setLoading(false);
        })
        .catch(() => {
          // eslint-disable-next-line no-alert, no-undef
          alert('There was a failure converting the PDF to images, please make sure your PDF has 8 pages or less. If this error continues to occur, please open a tools-help ticket.');
        });
    } else {
      setImgFile([URL.createObjectURL(fileInput.current.files[0])]);
    }
  };

  const decodeImgs = async (images) => {
    setLoading(true);
    try {
      // iterate through the images and decode them - update state just once
      const newUploads = [];
      for (let i = 0; i < images.length; i += 1) {
        const img = images[i];
        // await is needed here, do not remove despite the warning!!
        const reader = await pReader.current;
        const results = await reader.decode(img);
        for (let j = 0; j < results.length; j += 1) {
          const result = results[j];
          if (result.barcodeText.length < 25) {
            // eslint-disable-next-line no-console
            console.warn('found an invalid barcode, not adding to state.', result.barcodeText);
          } else {
            newUploads.push({ image: img, result: result.barcodeText, length: `${result.barcodeText[5]}` === '9' ? 9 : 6 });
          }
        }
        if (!results.length) {
          // eslint-disable-next-line no-undef
          alert('No barcode found');
        }
      }
      setImageUploads([...imageUploads, ...newUploads]);
      setLoading(false);
    } catch (ex) {
      if (ex.message.indexOf('network connection error')) {
        const customMsg = 'Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.';
        // eslint-disable-next-line no-undef
        alert(customMsg);
      }
      throw ex;
    }
    fileInput.current.value = '';
    setImgFile([]);
  };

  useEffect(() => {
    pReader.current = BarcodeReader.createInstance();
    (async () => {
      const settings = await (await pReader.current).getRuntimeSettings();
      settings.barcodeFormatIds_2 = EnumBarcodeFormat_2.BF2_POSTALCODE;
      (await pReader.current).updateRuntimeSettings(settings);
    })();
    return () => {
      (async () => {
        if (pReader.current) {
          (await pReader.current).destroyContext();
        }
      })();
    };
  }, []);

  return (
    <div className="ImgDecode">
      <div className="upload">
        <input type="file" onChange={showPreview} ref={fileInput} />
      </div>
      {imgFile && imgFile.map((img) => (
        <div className="imgPreview" key={img.slice(-10)}>
          <img src={img} alt="uploaded file preview" />
        </div>
      ))}
      {/* eslint-disable-next-line no-nested-ternary */}
      {imgFile.length > 0
        ? (imgFile.length === 1
          ? <button type="submit" onClick={() => decodeImgs(imgFile)}>Decode</button>
          : <button type="submit" onClick={() => decodeImgs(imgFile)}>Decode All</button>
        )
        : ''}
      {loading && <Loading />}
      <h1>Results</h1>
      <div className="results">
        {imageUploads && imageUploads.map((imageUpload, index) => (
          <div className="results-container" key={imageUpload.result}>
            <p>{`Barcode: ${imageUpload.result}`}</p>
            <div className="results-history">
              <div className="results-history-img">
                <img src={imageUpload.image} alt={`uploaded file preview #${index}`} />
              </div>
              <div className="results-history-barcode">
                <p>{`Barcode ID: ${imageUpload.result.slice(0, 2)}`}</p>
                <p>{`Service Type ID: ${imageUpload.result.slice(2, 5)}`}</p>
                <p>{`Mailer ID (${imageUpload.length} digits): ${imageUpload.result.slice(5, 5 + imageUpload.length)}`}</p>
                <p>{`Serial Number: ${imageUpload.result.slice(5 + imageUpload.length, 20)}`}</p>
                <p>{`Routing Code: ${imageUpload.result.slice(20, 31)}`}</p>
                <ul>
                  <li>{`5-digit Zip: ${imageUpload.result.slice(20, 25)}`}</li>
                  <li>{`4-digit Zip: ${imageUpload.result.slice(25, 29)}`}</li>
                  {imageUpload.result.length > 29 && (
                    <li>{`Delivery Point: ${imageUpload.result.slice(29, 31)}`}</li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImgDecode;
