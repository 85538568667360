/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';

/**
 * Default page for all unauthorized requests.
 * @returns Login prompt
 */
const Unauthorized = () => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: `${window.location.origin}/`,
      },
    });
  };

  return (
    <div id="unauthorized">
      <p>You are not authorized to view this page.</p>
      <p>
        If you are logged in with your MissionWired email address and still got this page, your session may have expired.
      </p>
      <p>
        Try
        {' '}
        <Link onClick={handleLogout}>logging in again</Link>
        .
      </p>
    </div>
  );
};

export default Unauthorized;
