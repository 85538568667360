/* eslint-disable class-methods-use-this */
import ClientTransform from './ClientTransform';

export const CLIENTLIST = [
  { ACA: { alg: 'MOD10 - 1212' } },
  { ADA: { alg: 'MOD10 - 1212' } },
  { DGA: { alg: 'MOD10 - 2121' } },
  { DSCC: { alg: 'MOD10 - 2121' } },
  { LDF: { alg: 'MOD10 - 2121' } },
  { Murphy: { alg: 'MOD10 - 2121' } },
  { Rochester: { alg: 'MOD10 - 2121' } },
  { SFMFB: { alg: 'MOD10 - 7532' } },
  { Warren: { alg: 'MOD10 - 2121' } },
];

export class DecodeScanLines {
  constructor(input) {
    if (!input || !input.client || !input.code) {
      throw new Error('Cannot process code, missing client or code input');
    }
    this.client = input.client;
    this.clientConfig = CLIENTLIST.find((c) => c[this.client])[this.client];
    this.code = input.code;
    this.checkDigit = null;
    this.computedCheckDigit = null;
  }

  generateTransform() {
    const transformedCode = [];
    // If the value is a number, just push it to the transformedCode array
    // otherwise, use the ClientTransform object to get the transformed value
    for (let i = 0; i < this.code.length; i += 1) {
      const x = this.code[i];
      if (this.isNumber(this.code[x])) {
        transformedCode.push(this.code[x]);
      } else {
        transformedCode.push(ClientTransform[this.client][this.code[x]]);
      }
    }
    this.checkDigit = Number(transformedCode.join('').slice(-1));
    return transformedCode.join('');
  }

  computeCheckDigit() {
    // get the sum of the transformed code
    const sum = this.sum();
    // round the sum to the nearest 10
    const rounded = Math.ceil(sum / 10) * 10;
    // subtract the sum from the rounded value to get the computed check digit
    this.computedCheckDigit = rounded - sum;
    // return true if the check digit is valid
    return this.checkDigit === this.computedCheckDigit;
  }

  sum() {
    const transformedCode = this.generateTransform();
    const code = transformedCode.slice(0, -1);

    let sum = 0; // add to the sum to return
    let digit = 0; // the digit of the code to evaluate
    let codeIndex = 0; // for iterating through the code
    let computeIndex = 0; // for iterating through the transform array

    // create an array based on the MOD10 calculation
    // i.e. MOD10 - 1212 => [1, 2, 1, 2]
    const computedArray = [...this.clientConfig.alg.matchAll(/MOD10 - (\d*)/g)][0][1]
      .toString().split('').map(Number);

    while (codeIndex < code.length && computeIndex < computedArray.length) {
      digit = parseInt(code.charAt(codeIndex), 10);
      // multiply the current digit by the corresponding value in the
      // computedArray then sum the digits of the result
      const digitSum = this.sumDigits(digit * computedArray[computeIndex]);
      sum += digitSum;
      codeIndex += 1;
      computeIndex += 1;
      if (computeIndex === computedArray.length) computeIndex = 0;
    }
    return sum;
  }

  // check if the value in the code is a number or not
  isNumber(n) {
    return !Number.isNaN(parseFloat(n)) && !Number.isNaN(n - 0);
  }

  // sum the digits of a number
  sumDigits(n) {
    return n.toString().split('').reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0);
  }
}
