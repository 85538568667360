import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './WelcomePage.css';

import Login from '../Login';
import Unauthorized from '../401';
import Button from '../Button/Button';

const WelcomePage = () => {
  const { user, error, isAuthenticated } = useAuth0();

  return (
    <div className="publicPage">
      {!isAuthenticated && error && (
        <Unauthorized />)}
      {!isAuthenticated && !error && (
        <>
          <h1> Hello, please log in to continue. </h1>
          <Login />
        </>
      )}

      {isAuthenticated && (
        <>
          <h1>
            {`Hello, ${user.email}.`}
          </h1>
          <div className="welcomeButtons">
            <Button
              text="Decode Barcode"
              path="/barcode"
            />
            <Button
              text="Decode Scanline"
              path="/scanline"
            />
          </div>
        </>
      )}

    </div>
  );
};

export default WelcomePage;
