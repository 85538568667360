import './HomePage.css';
import '../../dbr'; // import side effects. The license, engineResourcePath, so on.
import { BarcodeReader } from 'dynamsoft-javascript-barcode';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import ImgDecode from '../ImgDecode/ImgDecode';

const HomePage = () => {
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    (async () => {
      try {
        await BarcodeReader.loadWasm();
      } catch (ex) {
        if (ex.message.indexOf('network connection error')) {
          const customMsg = 'Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.';
          // eslint-disable-next-line no-alert, no-undef
          alert(customMsg);
        }
        throw ex;
      }
    })();
  }, []);

  return (
    isAuthenticated && (
    <div className="homePage">
      <h1>Upload an image or PDF* file with barcode(s) to decode.</h1>
      <h4>* Please limit PDFs to 8 pages</h4>
      <div className="container">
        <ImgDecode />
      </div>
    </div>
    )
  );
};

export default HomePage;
