import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// Simple button component to link to a page
const ButtonLink = (props) => {
  const { text, path } = props;

  return (
    <Link to={path}>
      <button type="submit">
        {text}
      </button>
    </Link>
  );
};

ButtonLink.propTypes = {
  text: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

export default ButtonLink;
