import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import Loading from './Spinner/Loading';

const ProtectedRoute = ({ component }) => {
  const Component = withAuthenticationRequired(component, {
    // eslint-disable-next-line react/no-unstable-nested-components
    onRedirecting: () => <Loading />,
  });

  return <Component />;
};

export default ProtectedRoute;

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
};
